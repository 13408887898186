import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/ORCHARD/key.jpg';
import product1 from '../../assets/solution-city-resized/ORCHARD/product1.png';
import product2 from '../../assets/solution-city-resized/ORCHARD/product2.png';
import product3 from '../../assets/solution-city-resized/ORCHARD/product3.png';
import product4 from '../../assets/solution-city-resized/ORCHARD/product4.png';

export default function OrchardModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Ripe for the Drinking</h2>
          </div>
          <span>
          Everyone loves fresh juice. And many of the juices you love are processed with SPX FLOW pasteurizers, mixers, valves and other equipment necessary to bring nature’s best flavors to your table. 
          </span>
          <span className="cta">Learn more about our equipment used for efficient juice production:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products">
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/falling-film-plate-evaporators/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Plate Evaporators</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/lightnin/products/70-series-medium-torque-top-entering-mixer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Mixers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/tubular-juice-pasteurizer-unit-or-system/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Pasteurizers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/d4-da4-series-double-seat-mix-proof-valves"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Valves</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

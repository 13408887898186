import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/PERSONAL CARE/key.jpg';
import product1 from '../../assets/solution-city-resized/PERSONAL CARE/product1.png';
import product3 from '../../assets/solution-city-resized/PERSONAL CARE/product3.png';

export default function PersonalCareModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Quality + Consistency in Personal Care</h2>
          </div>
          <span>
            The personal care industry relies on precise and consistent solutions to create the highest quality products
            possible. And from toothpaste and deodorant to shampoo and sunscreen, SPX FLOW equipment and knowhow are
            trusted by brands worldwide to deliver on their promises of quality.
          </span>
          <span className="cta">Check out our products trusted by major brands everywhere:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products two-right">
          <a
            className="product"
            href="https://www.spxflow.com/bran-luebbe/products/autoblend-system-body-lotion-production/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Pump Systems</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/cavitator-controlled-cavitation-mixer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Mixers</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

import './dot.scss';
import { ReactComponent as DotSvg } from '../../assets/target.svg';
import { cloneElement, useEffect, useState } from 'react';
import { useWindowSize } from '../hooks/useWindowSize';
import { animated, useSpring } from 'react-spring';

export default function PointOfInterest({ passTop, passLeft, parentRef, children, loaded, isOpen }) {
  const [left, setLeft] = useState(null);
  const [top, setTop] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const size = useWindowSize();
  const [{ scale }, dotApi] = useSpring(() => ({ scale: 1 }));

  useEffect(() => {
    setLeft(`${(passLeft * parentRef.current.scrollWidth) / 100}px`);
    setTop(`${(passTop * parentRef.current.clientHeight) / 100}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef.current?.scrollWidth, passLeft, passTop, size, loaded]);

  return (
    <>
      <animated.div
        className="dot"
        style={{ top, left, display: loaded ? 'block' : 'none', scale }}
        onMouseEnter={() => dotApi.start({ scale: 1.5 })}
        onMouseLeave={() => dotApi.start({ scale: 1 })}
      >
        <DotSvg onClick={openModal} />
      </animated.div>
      {cloneElement(children, { open: modalOpen, onClose: closeModal })}
    </>
  );

  function openModal() {
    setModalOpen(true);
    isOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
    isOpen(false);
  }
}

import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/ROADCONSTRUCTION/key.jpg';
// import product1 from '../../assets/solution-city-resized/ROADCONSTRUCTION/product1.png';
import product2 from '../../assets/solution-city-resized/ROADCONSTRUCTION/product2.png';
import product3 from '../../assets/solution-city-resized/ROADCONSTRUCTION/product3.png';

export default function OrchardModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Paving the Way Forward</h2>
          </div>
          <span>
          Roadways require routine maintenance to keep our journeys safe. SPX FLOW mixers and pumps are essential for asphalt continuity. 
          </span>
          <span className="cta">Check out how SPX FLOW technology and equipment helps keep drivers safe on the roadways:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products two-right">
          {/* <a
            className="product"
            href="https://www.spxflow.com/stone/products/dc-power-units"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Power Units</span>
          </a> */}
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump/products/combitherm-plus-high-temperature-centrifugal-pump/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Pumps</span>
          </a>
          <a
            className="product"
            href="https://www.philamixers.com/products/industrial-mixing-systems/side-entry-mixing-system/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Side Entry Mixers</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

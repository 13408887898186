import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/CANDY/key.jpg';
import product1 from '../../assets/solution-city-resized/CANDY/product1.png';
import product2 from '../../assets/solution-city-resized/CANDY/product2.png';
import product3 from '../../assets/solution-city-resized/CANDY/product3.png';
import product4 from '../../assets/solution-city-resized/CANDY/product4.jpg';

export default function CandyModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Making Life a Little Sweeter</h2>
          </div>
          <span>
            SPX FLOW’s line of precision-engineered technologies helps ensure your favorite candy is always delicious
            and consistent.
          </span>
          <span className="cta">Explore the SPX FLOW products that make candy taste great:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products">
          <a
            className="product"
            href="https://www.spxflow.com/bran-luebbe/products/novados-h1-metering-pump/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Dosing Pumps</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/waukesha-cherry-burrell/products/universal-2-series-positive-displacement-pumps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span className="smol">POSITIVE DISPLACEMENT PUMPS</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/gasketed-sanitary-plate-heat-exchangers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Heat Exchangers</span>
          </a>
          <a
            className="product"
            href="https://utgmix.com/food-beverage/applying-patented-sigma-mixing-technology-for-cocoa-mass-and-liquid-chocolate/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Mixers</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/INKS/key.jpg';
import product1 from '../../assets/solution-city-resized/INKS/product1.png';
import product2 from '../../assets/solution-city-resized/INKS/product2.png';
import product3 from '../../assets/solution-city-resized/INKS/product3.png';
import product4 from '../../assets/solution-city-resized/INKS/product4.jpg';

export default function InksModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>The Colors of Life</h2>
          </div>
          <span>
            Vibrant colors are part of life’s rich tapestry. At SPX FLOW, we provide solutions that contribute to the
            manufacturing of inks and dyes that elevate the human experience around the world.
          </span>
          <span className="cta">Explore the products that bring the human imagination to life:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products">
          <a
            className="product"
            href="https://www.spxflow.com/waukesha-cherry-burrell/products/d4-series-double-seat-mix-proof-valves/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Valves</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/waukesha-cherry-burrell/products/universal-3-series-positive-displacement-pumps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product3" />
            <span>PUMPS</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/waukesha-cherry-burrell/products/votator-extra-heavy-duty-series-scraped-surface-heat-exchangers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product2" />
            <span>Heat Exchangers</span>
          </a>

          <a
            className="product"
            href="https://www.philamixers.com/industries/paints-coatings/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Mixers</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

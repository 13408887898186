import { useEffect, useRef, useState } from 'react';
import './right-arrow.scss';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';

export default function RightArrow({ parentRef, onScroll, modalOpen }) {
  const modalOpenRef = useRef(modalOpen);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    parentRef.current.addEventListener('scroll', handleVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    modalOpenRef.current = modalOpen;
    if (modalOpen) setIsVisible(false);
    else handleVisible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  return (
    <div className="right-arrow" style={{ display: isVisible ? 'block' : 'none' }} onClick={onScroll}>
      <ChevronRight />
    </div>
  );

  function handleVisible() {
    const farRight = parentRef.current.scrollWidth - parentRef.current.clientWidth;
    if (!farRight) return;
    if (!modalOpenRef.current) setIsVisible(parentRef.current.scrollLeft < farRight);
  }
}

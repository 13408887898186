import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/BOAT/key.jpg';
import product1 from '../../assets/solution-city-resized/BOAT/product1.png';
import product2 from '../../assets/solution-city-resized/BOAT/product2.png';
import product3 from '../../assets/solution-city-resized/BOAT/product3.png';
import product4 from '../../assets/solution-city-resized/BOAT/product4.png';

export default function BoatModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Fun on the Water</h2>
          </div>
          <span>
            Our marine pump equipment and solutions are trusted on waterways everywhere for everything from keeping
            engines cool to keeping water out of the boat. We help make your experience on the water safe and enjoyable.
          </span>
          <span className="cta">Explore our range of marine pumping solutions:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products">
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump-marine/products/cartridge-submersible-bilge-pumps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Bilge Pumps</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump-marine/products/pump-sizes-f35-f95/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Cooling Pumps</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump-marine/products/talulah-ballast-pumps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Ballast Pumps</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump-marine/products/cartridge-aerator-pumps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Aeration Pumps</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

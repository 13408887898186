import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/LAUNDRY/key.jpg';
import product1 from '../../assets/solution-city-resized/LAUNDRY/product1.png';
import product2 from '../../assets/solution-city-resized/LAUNDRY/product2.png';
import product3 from '../../assets/solution-city-resized/LAUNDRY/product3.png';

export default function LaundryModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Clean and Fresh Solutions</h2>
          </div>
          <span>
            Brands trust us to ensure their laundry detergent delivers fresh and clean clothes every time. SPX FLOW
            pumps and systems directly contribute to the research, development and production of laundry detergent
            across the globe.
          </span>
          <span className="cta">Learn more about the products that make your favorite detergent consistent:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products custom-right">
          <a
            className="product"
            href="https://www.spxflow.com/bran-luebbe/products/autoblend-system-continuous-production-of-liquid-detergent/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Autoblend Systems</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/wplus-series-iec-centrifugal-pumps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Pumps</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/dt4-series-tank-outlet-double-seat-mix-proof-valves/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Valves</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

import { useEffect, useRef, useState } from 'react';
import './App.scss';
import background from './assets/background-with-bakery.svg';
import RightArrow from './components/arrows/right-arrow';
import useDraggable from './components/drag-scroll/drag-scroll';
import LeftArrow from './components/arrows/left-arrow';
import { useSpring, animated } from 'react-spring';
import PointOfInterest from './components/dots/dot';
import SandwichCookieModal from './points-of-interest/sandwich-cookie/modal';
// import AeroSpaceModal from './points-of-interest/aerospace/modal';
import EVModal from './points-of-interest/evs/modal';
import PharmaModal from './points-of-interest/pharma/modal';
import LaundryModal from './points-of-interest/laundry/modal';
import InksModal from './points-of-interest/inks/modal';
import PersonalCareModal from './points-of-interest/personal-care/modal';
import BeveragesModal from './points-of-interest/beverages/modal';
import BoatModal from './points-of-interest/boat/modal';
import ConstructionModal from './points-of-interest/construction/modal';
import PetsModal from './points-of-interest/pets/modal';
import YogurtModal from './points-of-interest/yogurt/modal';
import CandyModal from './points-of-interest/candy/modal';
// import PlaneModal from './points-of-interest/plane/modal';
import OrchardModal from './points-of-interest/orchard/modal';
// import AirtowerModal from './points-of-interest/air-tower/modal';
import RoadconstructionModal from './points-of-interest/road-construction/modal';
import GreenHouseModal from './points-of-interest/greenhouse/modal'
// import CommunicationTowerModal from './points-of-interest/communication-tower/modal'
import RestaurantModal from './points-of-interest/restaurant/modal'
import BreweryModal from './points-of-interest/brewery/modal'
import BakeryModal from './points-of-interest/bakery/modal'

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isModalOpenRef = useRef(isModalOpen);
  const [loaded, setLoaded] = useState(false);
  const dragRef = useRef(null);
  const [{ scroll }, api] = useSpring({ scroll: dragRef?.current?.scrollLeft }, [dragRef?.current?.scrollLeft]);
  useDraggable(api, dragRef, isModalOpen);
  useEffect(() => {
    if (!dragRef.current) return;
    dragRef.current.parentElement.style.overflowY = 'hidden';
    dragRef.current.addEventListener('wheel', (e) => {
      e.preventDefault();
      if (isModalOpenRef.current) return;
      api.start({ scroll: dragRef.current.scrollLeft + e.deltaY * 5 });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LeftArrow parentRef={dragRef} onScroll={scrollLeft} modalOpen={isModalOpen} />
      <RightArrow parentRef={dragRef} onScroll={scrollRight} modalOpen={isModalOpen} />
      <animated.main scrollLeft={scroll} ref={dragRef} className="flow-app">
        <PointOfInterest passTop="54" passLeft="31" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <SandwichCookieModal />
        </PointOfInterest>
        {/* <PointOfInterest passTop="8" passLeft="65.3" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <AeroSpaceModal />
        </PointOfInterest> */}
        {/* <PointOfInterest passTop="13" passLeft="30.5" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <PlaneModal />
        </PointOfInterest> */}
        <PointOfInterest passTop="56" passLeft="26.36" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <EVModal />
        </PointOfInterest>
        <PointOfInterest passTop="56" passLeft="20.8" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <PharmaModal />
        </PointOfInterest>
        <PointOfInterest passTop="56" passLeft="23.1" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <LaundryModal />
        </PointOfInterest>
        <PointOfInterest passTop="56" passLeft="60.7" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <InksModal />
        </PointOfInterest>
        <PointOfInterest passTop="47" passLeft="85.3" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <PersonalCareModal />
        </PointOfInterest>
        <PointOfInterest passTop="79" passLeft="23.4" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <BeveragesModal />
        </PointOfInterest>
        <PointOfInterest passTop="74" passLeft="47.3" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <BoatModal />
        </PointOfInterest>
        <PointOfInterest passTop="38.4" passLeft="44.3" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <ConstructionModal />
        </PointOfInterest>
        <PointOfInterest passTop="54" passLeft="77.3" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <PetsModal />
        </PointOfInterest>
        <PointOfInterest passTop="82" passLeft="60.25" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <YogurtModal />
        </PointOfInterest>
        <PointOfInterest passTop="78" passLeft="33.8" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <CandyModal />
        </PointOfInterest>

        {/* new changes as part of solution city 2.0 */}

        <PointOfInterest passTop="79" passLeft="2.2" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <OrchardModal />
        </PointOfInterest>

        {/* <PointOfInterest passTop="23" passLeft="24.2" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <AirtowerModal />
        </PointOfInterest> */}

        <PointOfInterest passTop="49" passLeft="40.5" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <RoadconstructionModal />
        </PointOfInterest>

        <PointOfInterest passTop="68" passLeft="71.4" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <GreenHouseModal />
        </PointOfInterest>

        {/* <PointOfInterest passTop="9" passLeft="74.8" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <CommunicationTowerModal />
        </PointOfInterest> */}

        <PointOfInterest passTop="79" passLeft="96.9" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <RestaurantModal />
        </PointOfInterest>

        <PointOfInterest passTop="80" passLeft="8.6" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <BreweryModal />
        </PointOfInterest>

        <PointOfInterest passTop="77" passLeft="78.3" parentRef={dragRef} loaded={loaded} isOpen={handleModalChange}>
          <BakeryModal />
        </PointOfInterest>

        <img src={background} onLoad={setLoaded} alt="background-img" />
      </animated.main>
    </>
  );

  function scrollLeft() {
    api.start({ scroll: dragRef.current.scrollLeft - dragRef.current.scrollWidth / 4 });
  }

  function scrollRight() {
    api.start({ scroll: dragRef.current.scrollLeft + dragRef.current.scrollWidth / 4 });
  }

  function handleModalChange(bool) {
    isModalOpenRef.current = bool;
    setIsModalOpen(bool);
  }
}

export default App;

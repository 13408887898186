import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/BREWERY/key.jpg';
import product1 from '../../assets/solution-city-resized/BREWERY/product1.png';
import product2 from '../../assets/solution-city-resized/BREWERY/product2.png';
import product3 from '../../assets/solution-city-resized/BREWERY/product3.png';
import product4 from '../../assets/solution-city-resized/BREWERY/product4.png';

export default function OrchardModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>A Time to Celebrate</h2>
          </div>
          <span>
          Everyone loves good times with great friends. Whether celebrating a birthday or a job well done, when people gather together to enjoy beer, wine and spirits, our innovations help make those spirits possible. 
          </span>
          <span className="cta">Learn how SPX FLOW clarifiers, pumps, valves and heat exchangers help make your favorite adult beverages:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products">
          <a
            className="product"
            href="https://www.spxflow.com/seital-separation/products/self-cleaning-clarifiers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Clarifiers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/gasketed-sanitary-plate-heat-exchangers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Heat Exchangers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/waukesha-cherry-burrell/products/d4-series-double-seat-mix-proof-valves/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Valves</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/waukesha-cherry-burrell/positive-displacement-pumps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Pumps</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

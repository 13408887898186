import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/GREENHOUSES/key.jpg';
import product1 from '../../assets/solution-city-resized/GREENHOUSES/product1.png';
import product2 from '../../assets/solution-city-resized/GREENHOUSES/product2.png';


export default function OrchardModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Fresh, Accessible Food</h2>
          </div>
          <span>
          With greenhouse technology, farmers can feed people regardless of environmental conditions. We help make this possible with precision equipment necessary for heating, cooling and water circulation. 
          </span>
          <span className="cta">Learn more on how we help greenhouses around the world feed people everywhere:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products two-right">
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump/products/combiline-centrifugal-pump-with-in-line-ports/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Pumps</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump/products/combiblochorti-close-coupled-centrifugal-pump/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Pumps</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

import { useEffect, useState, useRef } from 'react';
import './left-arrow.scss';
import { ReactComponent as ChevronLeft } from '../../assets/chevron-left.svg';

export default function LeftArrow({ parentRef, onScroll, modalOpen }) {
  const modalOpenRef = useRef(modalOpen);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    parentRef.current.addEventListener('scroll', handleVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    modalOpenRef.current = modalOpen;
    if (modalOpen) setIsVisible(false);
    else handleVisible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  // useEffect(() => {
  //   console.log('isVisible', isVisible);
  // }, [isVisible]);

  return (
    <div className="left-arrow" style={{ display: isVisible ? 'block' : 'none' }} onClick={onScroll}>
      <ChevronLeft />
    </div>
  );

  function handleVisible() {
    if (!modalOpenRef.current) setIsVisible(parentRef.current.scrollLeft > 0);
  }
}

import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/BEVERAGES/key.jpg';
import product1 from '../../assets/solution-city-resized/BEVERAGES/product1.png';
import product2 from '../../assets/solution-city-resized/BEVERAGES/product2.png';
import product3 from '../../assets/solution-city-resized/BEVERAGES/product3.png';
import product4 from '../../assets/solution-city-resized/BEVERAGES/product4.png';

export default function BeveragesModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Beverage Innovation</h2>
          </div>
          <span>
            SPX FLOW Nutrition & Health equipment and systems help people enjoy nutritious and delicious beverages from
            plants and nuts. We provide beverage brands with the tools and expertise necessary to create healthy
            recipes.
          </span>
          <span className="cta">Learn more about the products that help beverage companies innovate and deliver:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products">
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/infusion-uht-sdh/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>UHT</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/products/type?productType=systems&subtypes=clean-in-place-cip"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>CIP</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/capabilities/innovation-centers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Innovation Centers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/gasketed-sanitary-plate-heat-exchangers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Heat Exchangers</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/RESTAURANT/key.jpg';
import product1 from '../../assets/solution-city-resized/RESTAURANT/product1.png';
import product2 from '../../assets/solution-city-resized/RESTAURANT/product2.png';
import product3 from '../../assets/solution-city-resized/RESTAURANT/product3.png';
import product4 from '../../assets/solution-city-resized/RESTAURANT/product4.png';

export default function OrchardModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Have It Your Way </h2>
          </div>
          <span>
          Whether you prefer mustard, ketchup, mayonnaise or any other topping — SPX FLOW equipment and technology ensures that your favorite condiments, sauces and more are consistent every time. 
          </span>
          <span className="cta">Check out how our mixers, dryers, homogenizers and other equipment help make the things you enjoy most:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products">
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/flex-mix-processor-series-mixers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Flex Mix</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/anhydro/products/triple-a-dryers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Spray Dryers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/rannie-57t-and-gaulin-57t-series-high-pressure-homogenizers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Homogenizers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/cavitator-controlled-cavitation-mixer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Cavitators</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/PHARMA/key.jpg';
import product1 from '../../assets/solution-city-resized/PHARMA/product1.png';
import product3 from '../../assets/solution-city-resized/PHARMA/product3.png';
import product4 from '../../assets/solution-city-resized/PHARMA/product4.png';

export default function PharmaModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Here’s to Good Health</h2>
          </div>
          <span>
            SPX FLOW equipment and systems help deliver vital medicines around the world. We serve the global
            pharmaceutical industry in a range of ways, including blending active pharmaceutical ingredients (API) in
            medications and life-saving vaccines.
          </span>
          <span className="cta">Explore the products that contribute to the health of people everywhere:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products custom-right">
          <a
            className="product"
            href="https://www.spxflow.com/lightnin/products/stainless-steel-ecl-sanstar-portable-sanitary-mixer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Mixers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/rannie-57t-and-gaulin-57t-series-high-pressure-homogenizers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span className="ksmol">Homogenizers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/anhydro/products/micraspray-dryers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Spray Dryers</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

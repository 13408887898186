import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/YOGURT/key.jpg';
import product1 from '../../assets/solution-city-resized/YOGURT/product1.png';
import product2 from '../../assets/solution-city-resized/YOGURT/product2.png';
import product3 from '../../assets/solution-city-resized/YOGURT/product3.png';
import product4 from '../../assets/solution-city-resized/YOGURT/product4.png';

export default function YogurtModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>Nutritious, Delicious Yogurt</h2>
          </div>
          <span>
            At SPX FLOW global innovation centers, our food technologists and process engineers help the largest yogurt
            brands innovate efficiently to introduce new flavors for their customers.
          </span>
          <span className="cta">Check out how we help global brands stay ahead of trends:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products">
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/flex-mix-instant-series-batch-inline-or-continuous-mixers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Mixers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/milk-pasteurizer-unit-or-system/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Pasteurizers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/apv/products/d4-da4-series-double-seat-mix-proof-valves/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product3} alt="product3" />
            <span>Valves</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/assets/pdf/APV_Dosing-Station_BRO_GB_1119.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Dosing Stations</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/CONSTRUCTION/key.jpg';
// import product1 from '../../assets/solution-city-resized/CONSTRUCTION/product1.png';
// import product2 from '../../assets/solution-city-resized/CONSTRUCTION/product2.png';
import product4 from '../../assets/solution-city-resized/CONSTRUCTION/product4.png';

export default function ConstructionModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>We Built This City</h2>
          </div>
          <span>
            From buildings to bridges to roadways and more, SPX FLOW equipment and expertise help companies build and
            maintain core infrastructure in cities around the world.
          </span>
          <span className="cta">
            Learn about the remarkable products that help build and maintain core infrastructure:
          </span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products one-right">
          {/* <a
            className="product"
            href="https://www.spxflow.com/power-team/products/heavy-lift-positioning-solutions#series-1-section"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Motion Control</span>
          </a> */}
          {/* <a
            className="product"
            href="https://www.spxflow.com/power-team/products/cylinders"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>Cylinders</span>
          </a> */}
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump/products/combitherm-plus-high-temperature-centrifugal-pump/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>Pumps</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}

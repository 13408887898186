import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import '../modal.scss';
import cookie from '../../assets/solution-city-resized/EVS/key.jpg';
import product1 from '../../assets/solution-city-resized/EVS/product1.png';
import product2 from '../../assets/solution-city-resized/EVS/product2.png';
import product4 from '../../assets/solution-city-resized/EVS/product4.png';

export default function EVModal({ open, onClose }) {
  const bgStyles = useSpring({
    backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
  });
  const styles = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0)',
  });
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (open) {
      setZIndex(999);
    }
  }, [open]);

  return (
    <animated.main style={{ zIndex, ...bgStyles }} className="flow-modal" onClick={closeModal}>
      <animated.div className="modal-window" style={styles} onClick={(e) => e.stopPropagation()}>
        <div className="text">
          <div className="title">
            <ChevronRight />
            <h2>The Road to Sustainability</h2>
          </div>
          <span>
            SPX FLOW is right in the middle of the explosive growth in sustainable transportation. Our mixers, pumps and
            a range of other products help drive the global transition toward sustainable energy in the electric vehicle
            market.
          </span>
          <span className="cta">Discover how we serve the EV supply chain:</span>
          <button onClick={closeModal} className="close">
            X
          </button>
        </div>
        <div className="graphic">
          <img src={cookie} alt="demo-graphic" />
        </div>
        <div className="products custom-right">
          <a
            className="product"
            href="https://www.spxflow.com/lightnin/products/700800-series-high-torque-top-entering-mixer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product1} alt="product1" />
            <span>Mixers</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/johnson-pump/products/topair-self-priming-aodd-pump/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product2} alt="product2" />
            <span>DIAPHRAGM PUMPS</span>
          </a>
          <a
            className="product"
            href="https://www.spxflow.com/bran-luebbe/products/novados-h1-metering-pump/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={product4} alt="product4" />
            <span>DOSING PUMPS</span>
          </a>
        </div>
      </animated.div>
    </animated.main>
  );

  function closeModal() {
    onClose();
    setTimeout(() => setZIndex(-1), 500);
  }
}
